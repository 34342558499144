import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

import useGetPaginatedEquipments from '@/hooks/queries/equipments-api/useGetPaginatedEquipments';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { TEquipment } from '@/services/equipments/equipments.types';
import { getFeatureFlagValue } from '@/utils/utils';
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import { EditIcon, Loader, MoreHorizontal, PlusIcon } from 'lucide-react';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import { EquipmentAvailabilityModal } from '../../modals/equipment-availability-modal/EquipmentAvailabilityModal';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableSearchInput from '../common/table-search-input/TableSearchInput';

export function InventoryTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'equipmentType.name', desc: false }]
  });

  const {
    data: dataInventory,
    isLoading,
    isError,
    isFetching
  } = useGetPaginatedEquipments({
    pagination,
    filtering,
    sorting,
    columnFilters
  });

  const [openModal, setOpenModal] = useState(false);
  const [equipmentData, setEquipmentData] = useState<TEquipment>();

  const featureFlagCrudValue = getFeatureFlagValue('crud');
  const inventory = dataInventory?.results;
  const emptyData = !inventory?.length;
  const [search, setSearch] = useState(filtering || '');

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const columns = useMemo<ColumnDef<TEquipment>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'code',
        accessorKey: 'code',
        header: t('tables.inventory.columns.code')
      },

      {
        id: 'name',
        accessorKey: 'name',
        header: t('tables.inventory.columns.name')
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: t('tables.inventory.columns.description')
      },
      {
        id: 'equipmentType.name',
        accessorKey: 'equipmentType.name',
        header: t('tables.inventory.columns.category')
      },
      {
        id: 'sports',
        accessorKey: 'sports',
        accessorFn: ({ sports }) => {
          const stringSport = sports.map((sport) => sport.name).join(', ');
          return stringSport;
        },
        header: t('tables.inventoryCategory.serviceCategories'),
        enableSorting: false
      },

      {
        id: 'button',
        enableHiding: false,
        cell: ({ row }) => {
          const handleAvailability = () => {
            setEquipmentData(row.original);
            setOpenModal(true);
          };

          return (
            <>
              <Button className="hidden lg:block" onClick={handleAvailability}>
                {t('tables.inventory.columns.buttons.availability')}
              </Button>
              <div className="lg:hidden">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost">
                      <MoreHorizontal className="w-4 h-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={handleAvailability}>
                      {t('tables.inventory.columns.buttons.availability')}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          );
        }
      },
      {
        id: 'actions-edit',
        enableHiding: false,
        cell: ({ row }) => {
          const inventoryId = row.original.id;
          if (!featureFlagCrudValue) {
            return undefined;
          }
          return (
            <Link to={`./edit/${inventoryId}`} className="w-full">
              <EditIcon />
            </Link>
          );
        }
      }
    ],

    [t, featureFlagCrudValue]
  );

  const table = useReactTable({
    data: inventory ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: dataInventory?.total,
    pageCount: Math.ceil(
      (dataInventory?.total ?? 0) / (dataInventory?.limit ?? 1)
    ),
    manualPagination: true,
    //sort config
    enableMultiSort: false, // disable multi-sorting for the entire table
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    //filter config
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      sorting,
      globalFilter: filtering,
      pagination
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div
        className={`flex items-center gap-2 py-4 ${featureFlagCrudValue ? 'justify-between' : 'justify-end'}`}
      >
        {featureFlagCrudValue && (
          <Button
            onClick={() => navigate('./create')}
            className="flex items-center gap-2"
          >
            <PlusIcon className="w-5" />
            <p>{t('form.create')}</p>
          </Button>
        )}
        <div className="flex flex-col-reverse items-start justify-center gap-2 md:flex-row">
          <div className="flex items-center w-full gap-3">
            <TableSearchInput value={search ?? ''} onChange={onSearch} />
            {search && isFetching && (
              <Loader className="text-gray-600 animate-spin" />
            )}
          </div>
          <TableColumnsToggle disabled={emptyData} table={table} />
        </div>
      </div>
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
      {openModal && equipmentData ? (
        <EquipmentAvailabilityModal
          open={openModal}
          onOpenChange={setOpenModal}
          equipmentData={equipmentData}
        />
      ) : null}
    </>
  );
}
